import { IConfig } from "shared/types/configuration";
import HttpClient from "./httpClient";
import { RcFile } from "antd/lib/upload";
import { MarketingMaterial } from "shared/types/marketingMaterials";
import {
  ParsedHTML,
  ParsedIDML,
  Template,
  Disclosure,
  DisclosureRequest,
  ExtendedDisclosure,
  Product,
  ProductRequest,
  Account,
  DisclosureFilterRequest,
  Multilingual,
  AgentFeedInfo,
  FileType,
  FileDrawerMode,
  type RenderIdmlProps,
} from "shared/types/salesEnablement";
import { AgentProfile } from "screens/adLibrary/marketingMaterialDrawer/hooks/agentManagement";
import { AgentProfileFormValues } from "screens/agentProfiles/agentProfileDrawer/AgentProfileDrawerForm";
import { ArchiveItem } from "shared/types/salesEnablementArchive";

type Response<T> = {
  result: T;
  error?: Error;
};

export default ({ config }: { headers: any; config: IConfig }) => ({
  getTemplates: () =>
    HttpClient.get<Response<Template[]>>(
      config.services.salesEnablement.templatesUrl,
    ),
  getPresignedUrl: (file: RcFile) =>
    HttpClient.post<
      Response<{ bucket: string; filename: string; url: string; id: string }>
    >(config.services.salesEnablement.presignedUrl, {
      filename: file.name,
    }),
  upload: (file: RcFile, url: string) =>
    fetch(url, { method: "PUT", body: file }),
  getFileInfo: (id: string, filename: string, attrs: string) =>
    HttpClient.get<Response<{ type: FileType }>>(
      `${config.services.salesEnablement.getFileInfoUrl}?id=${id}&filename=${filename}&attrs=${attrs}`,
    ),
  parseIdml: (bucket: string, filename: string, id: string) => {
    const url = `${config.services.salesEnablement.parseIdmlUrl}?bucket=${bucket}&filename=${filename}&id=${id}`;
    return HttpClient.post<Response<ParsedIDML>>(url, {});
  },
  renderIdml: async ({
    data,
    sp,
    variables,
  }: RenderIdmlProps): Promise<string | undefined> => {
    const url = config.services.salesEnablement.renderIdmlUrl;
    const res = await HttpClient.post<{
      result: { base64?: string; url?: string };
      error: { message: string };
    }>(url, {
      templateId: sp?.entities?.template.id,
      scriptId: sp?.entities?.script.id,
      data,
      variables,
    });
    const { result, error } = res;
    const { url: previewUrl, base64 } = result;
    if (!url && !base64)
      throw new Error(
        error?.message ??
          "Failed to render IDML. Either url or base64 must be defined.",
      );

    if (previewUrl) return previewUrl;

    if (!base64)
      throw new Error(
        error.message ??
          "Failed to render IDML. base64 string must be defined.",
      );
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  },
  parseHtml: (bucket: string, key: string, id: string) => {
    const url = `${config.services.salesEnablement.parseHtmlUrl}`;
    return HttpClient.post<Response<ParsedHTML>>(url, {
      file: { bucket, key, id },
    });
  },
  saveTemplate: (form: any, mode: FileDrawerMode) =>
    HttpClient.post<Response<Template>>(
      config.services.salesEnablement.templatesUrl,
      { form, mode },
    ),
  getProducts: () =>
    HttpClient.get<Response<Product[]>>(
      config.services.salesEnablement.productsUrl,
    ),
  createProduct: (product: ProductRequest) =>
    HttpClient.post<Response<Product>>(
      config.services.salesEnablement.productsUrl,
      product,
    ),
  updateProduct: (product: Product) =>
    HttpClient.put<Response<Product>>(
      `${config.services.salesEnablement.productsUrl}/${product.id}`,
      product,
    ),
  deleteProduct: (id: string) =>
    HttpClient.delete<Response<Product>>(
      `${config.services.salesEnablement.productsUrl}/${id}`,
    ),
  getDisclosures: () =>
    HttpClient.get<Response<ExtendedDisclosure[]>>(
      config.services.salesEnablement.disclosuresUrl,
    ),
  createDisclosure: (disclosure: DisclosureRequest) =>
    HttpClient.post<Response<Disclosure>>(
      config.services.salesEnablement.disclosuresUrl,
      disclosure,
    ),
  updateDisclosure: (disclosure: Disclosure) =>
    HttpClient.put<Response<Disclosure>>(
      `${config.services.salesEnablement.disclosuresUrl}/${disclosure.id}`,
      disclosure,
    ),
  deleteDisclosure: (id: string) =>
    HttpClient.delete<Response<Disclosure>>(
      `${config.services.salesEnablement.disclosuresUrl}/${id}`,
    ),
  filterDisclosures: (disclosureFilter: DisclosureFilterRequest) => {
    const params = new URLSearchParams(disclosureFilter);
    return HttpClient.get<Response<{ disclosures: Multilingual<string>[] }>>(
      `${config.services.salesEnablement.disclosuresUrl}/filter?${params}`,
    );
  },
  // MarketingMaterials
  // TODO: throw if error

  getMarketingMaterials: (paginationKey?: string) => {
    return HttpClient.get<{
      items: MarketingMaterial[];
      paginationKey?: string;
    }>(
      `${config.services.salesEnablement.marketingMaterialsUrl}?${
        paginationKey ? `paginationKey=${paginationKey}` : ""
      }`,
    );
  },

  // END MarketingMaterials
  getAccounts: () =>
    HttpClient.get<Response<Account[]>>(
      config.services.salesEnablement.accountsUrl,
    ),
  createOrUpdateAccount: (form: Account) =>
    HttpClient.post<Account>(config.services.salesEnablement.accountsUrl, {
      form,
    }),
  deleteAccounts: (ids: string[]) =>
    HttpClient.delete<Account[]>(config.services.salesEnablement.accountsUrl, {
      ids,
    }),
  getAgentFeedInfo: async ({
    subUUID,
    email,
  }: {
    subUUID?: string;
    email?: string;
  }) => {
    const params = new URLSearchParams({
      ...(subUUID && { subUUID }),
      ...(email && { email }),
    });
    return HttpClient.get<AgentFeedInfo>(
      `${config.services.salesEnablement.agentFeedInfoUrl}?${params}`,
    );
  },
  uploadRenderAsset: (key: string) =>
    HttpClient.post<Response<{ id: string }[]>>(
      `${config.services.salesEnablement.renderAsset}`,
      {
        key,
        bucket: config.s3AssetBucket,
      },
    ),
  getAgentProfiles: () => {
    return HttpClient.get<AgentProfile[]>(
      config.services.salesEnablement.agentProfilesUrl,
    );
  },
  createAgentProfile: (agentProfile: AgentProfileFormValues) => {
    return HttpClient.post<AgentProfile>(
      config.services.salesEnablement.agentProfilesUrl,
      agentProfile,
    );
  },
  updateAgentProfile: (agentProfile: AgentProfile) => {
    return HttpClient.put<AgentProfile>(
      `${config.services.salesEnablement.agentProfilesUrl}/${agentProfile.id}`,
      agentProfile,
    );
  },
  deleteAgentProfiles: (ids: string[]) => {
    return HttpClient.delete<string[]>(
      config.services.salesEnablement.agentProfilesUrl,
      { ids },
    );
  },
  getArchives: (paginationKey?: string) => {
    return HttpClient.get<
      Response<{ archives: ArchiveItem[]; paginationKey?: string }>
    >(
      `${config.services.salesEnablement.archivesUrl}?${
        paginationKey ? `paginationKey=${paginationKey}` : ""
      }`,
    );
  },
  createArchive: (archive: ArchiveItem) => {
    return HttpClient.post<ArchiveItem>(
      config.services.salesEnablement.archivesUrl,
      archive,
    );
  },
  updateArchive: (archive: ArchiveItem) => {
    return HttpClient.put<ArchiveItem>(
      `${config.services.salesEnablement.archivesUrl}/${archive.id}`,
      archive,
    );
  },
  deleteArchives: (ids: string[]) => {
    return HttpClient.delete<string[]>(
      config.services.salesEnablement.archivesUrl,
      { ids },
    );
  },
  processPdfTemplate: ({
    id,
    bucket,
    filename,
  }: {
    id: string;
    bucket: string;
    filename: string;
  }) => {
    return HttpClient.post<
      Response<{
        fileUrl: string;
        thumbnailUrls?: { original: string; small: string };
      }>
    >(config.services.salesEnablement.processPdfTemplateUrl, {
      id,
      bucket,
      filename,
    });
  },
  addRecentTemplate: (userId: string, templateId: string) => {
    return HttpClient.post<Response<Template>>(
      `${config.services.salesEnablement.templatesUrl}/recent-templates`,
      { userId, templateId },
    );
  },
  getRecentTemplates: (userId: string) =>
    HttpClient.get<Response<Template[]>>(
      `${config.services.salesEnablement.templatesUrl}/recent-templates/${userId}`,
    ),
});
