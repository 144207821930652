import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Row,
  Space,
  Typography,
} from "antd";
import { matchPath, useLocation, useParams } from "react-router-dom";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { ROUTES } from "../utils/constants";
import { RateTable } from "./printCheckout/RateTable";
import { ShippingDataFields } from "./printCheckout/ShippingDataFields";
import { MarketingMaterialPrintDeliveryForm } from "shared/types/marketingMaterials";
import styles from "./PrintCheckout.module.scss";

const printCheckoutFormId = "printCheckoutForm";

export const PrintCheckout = ({
  form,
}: {
  form: FormInstance<MarketingMaterialPrintDeliveryForm>;
}) => {
  const navigateWithSearch = useNavigateWithSearch();
  const location = useLocation();
  const { materialId } = useParams();
  const onClose = () => {
    navigateWithSearch(ROUTES.print(materialId ?? ""));
    form.resetFields(["shippingData"]);
  };

  const isPrintCheckoutVisible = !!matchPath(
    { path: ROUTES.printCheckout(materialId) },
    location.pathname,
  );

  return (
    <Drawer
      title="Order Prints"
      width="70%"
      visible={isPrintCheckoutVisible}
      onClose={onClose}
      className={styles.drawer}
      destroyOnClose
      footerStyle={{ textAlign: "right" }}
      bodyStyle={{ padding: 0 }}
      footer={
        <Space className={styles.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit" form={printCheckoutFormId}>
            Place Order
          </Button>
        </Space>
      }
    >
      <Row gutter={48} className={styles.row}>
        <Col span={12}>
          <Form.Item name="shippingData" noStyle>
            <ShippingDataFields />
          </Form.Item>
        </Col>
        <Col span={12} className={styles.summaryCol}>
          <Form.Item shouldUpdate className={styles.summary}>
            {() => {
              const formData = form.getFieldsValue();
              const { materials, shippingMethod } = formData;
              return (
                <>
                  <Typography.Title level={5}>Order Summary</Typography.Title>
                  <RateTable
                    materials={materials}
                    shippingMethod={shippingMethod}
                    form={form}
                  />
                </>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Drawer>
  );
};
