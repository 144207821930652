import { useV3Client } from "shared/hooks/av3/useV3Client";
import { useV3Accounts } from "shared/hooks/av3/useV3Accounts";
import { useV3Brands } from "shared/hooks/av3/useV3Brands";

export const usePortalExportData = () => {
  const { client, loading: clientLoading } = useV3Client();
  const { brands, loading: brandsLoading } = useV3Brands(client?.id);
  const { accounts, loading: accountsLoading } = useV3Accounts(client?.id);

  return {
    client,
    brands,
    accounts,
    loading: clientLoading || brandsLoading || accountsLoading,
  };
};
