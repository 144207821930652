import {
  Button,
  Drawer,
  Tabs,
  TabsProps,
  Skeleton,
  Form,
  Divider,
  Tooltip,
} from "antd";
import styles from "./FileDrawer.module.scss";
import moment from "moment";
import Layers from "./fileDrawer/Layers";
import {
  Template,
  Page,
  Layer,
  Language,
  Multilingual,
  OtherGroupLayer,
} from "shared/types/salesEnablement";
import { deliveryMethods, toUploadFile } from "screens/designStudio/utils";
import Preview from "../shared/Preview";
import { isFileCustomizable, validateLayers } from "./fileDrawer/utils";
import { useSalesEnablementContext } from "../hooks/SalesEnablementDataProvider";
import { useMutation, useQueryClient } from "react-query";
import API from "services";
import { useUser } from "shared/hooks/useUser";
import {
  toConditionalGroupLayer,
  toOptionGroupLayer,
  toOtherGroupLayer,
  toTextLayer,
  validate,
} from "./FileDrawer.utils";
import TemplateRenderDataProvider from "../hooks/TemplateRenderDataProvider";
import {
  errorNotification,
  successNotification,
} from "shared/components/customNotification/Notification";
import { useMemo, useState } from "react";
import UploadDragger from "./fileDrawer/properties/UploadDragger";
import { StatusSelector } from "./fileDrawer/StatusSelector";
import {
  onConfirmClose,
  onConfirmDeleteTab,
  onConfirmPublish,
} from "../shared/components/publish";
import { SettingsFields, ComponentsFields } from "./fileDrawer/FormFields";
import {
  fields,
  tabFields,
  fieldIsInvalid,
  Tab,
  TemplateForm,
} from "./fileDrawer/FormFields.schema";
import DisabledFieldsAlert from "./fileDrawer/DisabledFieldsAlert";
import useNavigateWithSearch from "shared/hooks/useNavigateWithSearch";
import { ROUTES } from "./constants";

const languages: Language[] = ["en", "es"];

type Props = {
  onClose: () => void;
  open: boolean;
  componentsTabsFilter: string;
  setComponentsTabsFilter: React.Dispatch<React.SetStateAction<string>>;
};

const tabs: { label: string; key: Tab }[] = [
  {
    label: "Settings",
    key: "settings",
  },
  {
    label: "Components",
    key: "components",
  },
];

const componentsTabs: { label: string; key: Language }[] = [
  {
    label: "English",
    key: "en",
  },
  {
    label: "Spanish",
    key: "es",
  },
];

const notification = (error?: boolean) => {
  if (error) {
    return errorNotification({
      messageLabel: "Please fix all errors before saving",
      size: "big",
    });
  }
  return successNotification({
    messageLabel: "The template has been saved",
    size: "big",
  });
};

const FileDrawer = (props: Props) => {
  const { onClose, open } = props;
  const [activeTab, setActiveTab] = useState<Tab>("settings");
  const [emptyFileError, setEmptyFileError] = useState<Multilingual<boolean>>({
    en: false,
    es: false,
  });
  const [requiredVariablesError, setRequiredVariablesError] = useState<
    Multilingual<boolean>
  >({
    en: false,
    es: false,
  });
  const {
    uploadForm,
    onSearchLayersBy,
    searchLayersBy,
    files,
    hasFiles,
    setFiles,
    data,
    selectedTemplate,
    isFormDirty,
    setIsFormDirty,
    status,
    setStatus,
    setSelectedTemplate,
    drawerDisabledMap,
    setDrawerDisabledMap,
    fileDrawerMode,
    setFileDrawerMode,
    activeLanguageTab,
    setActiveLanguageTab,
  } = useSalesEnablementContext();
  const navigate = useNavigateWithSearch();

  const isNew = !selectedTemplate;
  const filteredcomponentsTabs =
    props.componentsTabsFilter === "all"
      ? componentsTabs
      : componentsTabs.filter(
          tab => !props.componentsTabsFilter.includes(tab.key),
        );

  const { activeFile } = useMemo(
    () => ({
      activeFile: files?.[activeLanguageTab],
    }),
    [activeLanguageTab, files],
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (form: Partial<Record<keyof Template, any>>) =>
      API.services.salesEnablement.saveTemplate(form, fileDrawerMode ?? "edit"),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("sales-enablement-templates");
      },
    },
  );

  const user = useUser();

  const removeFileTab = (tabKey: string) => {
    setFiles(prev => {
      const { [tabKey as keyof typeof prev]: _, ...rest } = prev;
      return rest;
    });
    uploadForm?.setFieldsValue({
      [tabKey === "en" ? "englishFormNumber" : "spanishFormNumber"]: undefined,
    });
    props.setComponentsTabsFilter(tabKey as typeof props.componentsTabsFilter);
    setActiveLanguageTab(tabKey === "en" ? "es" : "en");
    if (uploadForm) validateFields({ tab: "components" });
  };

  const onEdit: TabsProps["onEdit"] = (tabKey, action) => {
    if (action === "add") {
      if (filteredcomponentsTabs.length === 1) {
        props.setComponentsTabsFilter("all");
        setActiveLanguageTab(activeLanguageTab === "en" ? "es" : "en");
        setEmptyFileError(prev => ({
          ...prev,
          [activeLanguageTab === "en" ? "es" : "en"]: false,
        }));
      }

      return;
    }
    if (action === "remove") {
      if (props.componentsTabsFilter === "all") {
        setEmptyFileError(prev => ({
          ...prev,
          [tabKey as Language]: false,
        }));

        if (
          !files[tabKey as keyof typeof files] ||
          files[tabKey as keyof typeof files]?.status !== "done"
        ) {
          return removeFileTab(tabKey as string);
        }
        onConfirmDeleteTab({
          onOk: () => removeFileTab(tabKey as string),
          label:
            componentsTabs.find(lg => lg.key === tabKey)?.label ??
            `this language`,
        });
      }
    }
  };

  const save = async () => {
    try {
      if (!hasFiles) throw new Error("At least 1 file must be defined");

      const orgForm = uploadForm?.getFieldsValue();
      if (!orgForm) throw new Error("Form must be defined");

      const formToSubmit = Object.keys(orgForm).reduce<Partial<Template>>(
        (acc, key) => {
          const val = orgForm[key as keyof TemplateForm];
          if (!val) return acc; // skip empty values
          if (
            key === `${fields.deliveryMethods.key}_en` ||
            key === `${fields.deliveryMethods.key}_es`
          )
            return acc;
          if (key === "expirationDate" && val) {
            return {
              ...acc,
              expirationDate: moment
                .utc(val)
                .tz("America/New_York")
                .endOf("month")
                .toISOString(),
            };
          }
          if (key === "englishFormNumber" || key === "spanishFormNumber") {
            return {
              ...acc,
              formNumber: {
                ...acc.formNumber,
                [key === "englishFormNumber" ? "en" : "es"]: val,
              },
            };
          }
          return {
            ...acc,
            [key]: val,
          };
        },
        {},
      );

      const filesWithLayers = { ...files };
      let isCustomizable = false;

      Object.entries(files).forEach(([language, file]) => {
        if (!file) return null;

        const emailDeliveryMethod = deliveryMethods.find(
          method => method.value === "email",
        );
        const downloadDeliveryMethod = deliveryMethods.find(
          method => method.value === "download",
        );

        if (!emailDeliveryMethod?.value || !downloadDeliveryMethod?.value)
          throw new Error("Correctly delivery method not found. ");

        const formDeliveryMethods = uploadForm?.getFieldValue(
          `${fields.deliveryMethods.key}_${language}`,
        );

        if (!formDeliveryMethods || formDeliveryMethods.length === 0) {
          throw new Error("Please select at least one delivery method option.");
        }

        const { id, url, pages, type, name, fileName, spMetadata, thumbnail } =
          file;
        if (type === "pdf") {
          filesWithLayers[language as keyof typeof files] = {
            id,
            url,
            type,
            name,
            fileName,
            thumbnail,
            deliveryMethods: [downloadDeliveryMethod.value],
          };
        } else {
          const isLayer = (page: unknown): page is Layer => !!page;
          // We need to replace layers in each page with filtered layers for latest updates.
          const pagesWithLayers: Page[] | undefined = pages?.map(page => {
            const layers: Layer[] =
              page.layers
                ?.map(p =>
                  data[language as Language]?.filteredLayers?.find(
                    fp => fp.id === p.id,
                  ),
                )
                .filter(isLayer) ?? [];
            return {
              ...page,
              layers,
            };
          });

          if (!pagesWithLayers)
            throw new Error("Pages with layers must be defined");

          if (!isCustomizable) {
            isCustomizable = pagesWithLayers.some(
              page => page.layers && isFileCustomizable(page.layers),
            );
          }

          filesWithLayers[language as keyof typeof files] = {
            id,
            url,
            pages: pagesWithLayers,
            type,
            name,
            fileName,
            spMetadata,
            thumbnail,
            htmlSnippetData: file.htmlSnippetData,
            deliveryMethods: formDeliveryMethods,
          };
        }
      });

      const statusHistory =
        fileDrawerMode === "duplicate"
          ? [status]
          : [status, ...(selectedTemplate?.statusHistory ?? [])];

      const template: Partial<Template> = {
        ...formToSubmit,
        status,
        files: filesWithLayers,
        createdBy: user.sub,
        lastUpdatedBy: user.sub,
        id: selectedTemplate?.id,
        customizable: isCustomizable,
        statusHistory,
        description: formToSubmit.description ?? "",
      };

      const validation = validate(template);
      if (validation.status !== "success") throw new Error(validation.message);

      const { result: savedTemplate } = await mutation.mutateAsync(template);

      if (!savedTemplate)
        throw new Error("There was an error saving the template");

      setIsFormDirty(false);
      setSelectedTemplate(savedTemplate);

      if (savedTemplate.statusHistory.includes("PUBLISHED")) {
        setDrawerDisabledMap({
          settings: true,
          files: {
            en: !!savedTemplate.files.en,
            es: !!savedTemplate.files.es,
          },
        });
      }

      notification();
      if (fileDrawerMode !== "edit") {
        setFileDrawerMode("edit");
        navigate(ROUTES.edit(savedTemplate.id));
      }
    } catch (error) {
      notification(true);
    }
  };

  const preClose = () => {
    if (
      (!!selectedTemplate && status !== selectedTemplate?.status) ||
      isFormDirty ||
      fileDrawerMode === "duplicate"
    ) {
      onConfirmClose({
        onOk: () => {
          setActiveLanguageTab("en");
          setActiveTab("settings");
          onClose();
        },
        content: "",
      });

      return;
    }

    setActiveLanguageTab("en");
    setActiveTab("settings");
    onClose();
  };

  const validateFile = (lang: Language) => {
    const fileReady = files?.[lang]?.status === "done";
    setEmptyFileError(s => ({ ...s, [lang]: !fileReady }));
    return fileReady;
  };

  const validateSmartVariable = (layer: Layer) => {
    if (
      !layer.smartVariable ||
      !requiredVariablesError[activeLanguageTab] ||
      !["expirationDate", "formNumber"].includes(layer.smartVariable)
    )
      return;

    const lang = activeLanguageTab;

    const { smartVariable } = layer;
    const otherSmartVariable =
      smartVariable === "expirationDate" ? "formNumber" : "expirationDate";

    const secondRequired = data[lang as Language]?.filteredLayers?.some(
      l => l.smartVariable === otherSmartVariable,
    );

    setRequiredVariablesError(prev => ({ ...prev, [lang]: !secondRequired }));
  };

  const validateSmartVariables = (lang: Language) => {
    if (!files?.[lang] || files[lang]?.type === "pdf") {
      setRequiredVariablesError(s => ({ ...s, [lang]: false }));

      return true;
    }

    const hasValidLayers = validateLayers(data[lang].filteredLayers);
    setRequiredVariablesError(s => ({ ...s, [lang]: !hasValidLayers }));

    return hasValidLayers;
  };

  const filteredFields = (tab: Tab) => {
    if (tab === "settings") return tabFields("settings");

    const openTabs = filteredcomponentsTabs.map(tab => tab.key);
    const fields = tabFields("components").filter(field => {
      if (field === "englishFormNumber" && !openTabs.includes("en")) {
        return false;
      }
      if (field === "spanishFormNumber" && !openTabs.includes("es")) {
        return false;
      }
      return true;
    });

    return fields;
  };
  const [isFormValid, setIsFormValid] = useState(false);

  const validateFields = async ({
    tab,
    overrideFields,
    validateOnly = true,
  }: {
    tab: Tab;
    overrideFields?: ReturnType<typeof filteredFields>;
    validateOnly?: boolean;
  }) => {
    const fieldKeys = overrideFields ?? filteredFields(tab);

    return new Promise<boolean>(res => {
      if (!uploadForm) return res(false);

      if (!validateOnly) {
        return uploadForm
          .validateFields(fieldKeys)
          .then(() => res(true))
          .catch(() => res(false));
      }

      // our current version of antd doesn't support
      // the "validateOnly" prop, so we do it manually here
      const isInvalid = fieldKeys.some(field => {
        if (!fields[field].required) return false;
        const value = uploadForm.getFieldValue(field);
        return fieldIsInvalid(value);
      });

      return res(!isInvalid);
    }).then(isValid => {
      setIsFormValid(isValid);
      return isValid;
    });
  };

  const validateLanguageTab = async (lang: Language) => {
    const tab = "components";
    const removableFormNumber =
      lang === "en" ? "spanishFormNumber" : "englishFormNumber";

    const validFile = validateFile(lang);
    const validFields = await validateFields({
      overrideFields: tabFields(tab).filter(f => f !== removableFormNumber),
      validateOnly: false,
      tab,
    });
    const validSmartVariables = validateSmartVariables(lang);

    return validFile && validFields && validSmartVariables;
  };

  const preSave = async () => {
    // validate active language tab
    let savingDisabled = false;
    const validActiveTab = await validateLanguageTab(activeLanguageTab);

    if (!validActiveTab) savingDisabled = true;

    if (props.componentsTabsFilter === "all") {
      const lang = activeLanguageTab === "en" ? "es" : "en";
      const validSecondTab = await validateLanguageTab(lang);
      if (!validSecondTab) savingDisabled = true;

      if (validActiveTab && !validSecondTab) {
        setActiveLanguageTab(lang);
      }
    }

    if (savingDisabled) return notification(true);

    const isPublishing = status === "PUBLISHED";

    const hasBeenPublished =
      selectedTemplate?.statusHistory.includes("PUBLISHED");

    const enFileUpdate =
      typeof files?.en !== typeof selectedTemplate?.files?.en;
    const esFileUpdate =
      typeof files?.es !== typeof selectedTemplate?.files?.es;

    const canUpdatefiles = enFileUpdate || esFileUpdate;

    if (
      isPublishing &&
      (isNew || !hasBeenPublished || (hasBeenPublished && canUpdatefiles))
    ) {
      return onConfirmPublish({ onOk: save });
    }

    save();
  };

  const duplicate = () => {
    setActiveTab("settings");
    const orgForm = uploadForm?.getFieldsValue();
    uploadForm?.setFieldsValue({
      ...orgForm,
      name: `Copy of ${orgForm?.name}`,
    });
    setStatus("UNPUBLISHED");
    setDrawerDisabledMap({
      settings: false,
      files: {
        en: false,
        es: false,
      },
    });
    setFileDrawerMode("duplicate");
    successNotification({
      messageLabel: "The template has been successfully duplicated",
      size: "big",
    });
  };

  return (
    <Drawer
      push={false}
      title={<b>{fileDrawerMode === "edit" ? "Edit" : "New"} Template</b>}
      placement="right"
      onClose={preClose}
      afterVisibleChange={async open => {
        if (open) {
          await validateFields({ tab: "settings" });
        }
      }}
      visible={open}
      width={`calc(100% - 48px)`}
      bodyStyle={{ padding: 0 }}
      closeIcon={null}
      footer={
        <div className={styles.footer}>
          <div className={styles.cta}>
            <StatusSelector />
            <Tooltip
              visible={
                isFormDirty || fileDrawerMode !== "edit" ? undefined : false
              }
              title="Please save the template before duplicating it"
            >
              <Button
                onClick={duplicate}
                disabled={isFormDirty || fileDrawerMode !== "edit"}
              >
                Duplicate
              </Button>
            </Tooltip>
          </div>
          <div className={styles.cta}>
            <Button onClick={preClose}>Close</Button>
            {activeTab === "settings" && (
              <Button
                type="primary"
                onClick={async () => {
                  const isValid = await validateFields({
                    tab: "settings",
                    validateOnly: false,
                  });
                  if (!isValid) return notification(true);

                  setActiveTab("components");
                }}
              >
                Next
              </Button>
            )}
            {activeTab === "components" && (
              <Button
                loading={mutation.isLoading}
                type="primary"
                onClick={preSave}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.tabsContainer}>
          <Tabs
            className={styles.tabs}
            tabPosition={"left"}
            defaultActiveKey={"settings"}
            activeKey={activeTab}
            onChange={async activeKey => {
              await validateFields({ tab: activeKey as Tab });
              setActiveTab(activeKey as Tab);
            }}
          >
            {tabs.map(tab => (
              <Tabs.TabPane
                className={styles.tabPane}
                tab={tab.label}
                key={tab.key}
                disabled={activeTab === "settings" && !isFormValid}
              >
                <div className={styles.content}>
                  <Form
                    form={uploadForm}
                    layout="vertical"
                    onValuesChange={async () => {
                      await validateFields({ tab: activeTab });
                      setIsFormDirty(uploadForm?.isFieldsTouched() ?? false);
                    }}
                  >
                    {tab.key === "settings" && (
                      <>
                        <DisabledFieldsAlert
                          visible={drawerDisabledMap?.settings}
                          className="settings"
                        />
                        <SettingsFields
                          disabled={drawerDisabledMap?.settings}
                        />
                      </>
                    )}
                    {tab.key === "components" && (
                      <Tabs
                        className={styles.componentsTabs}
                        tabPosition={"top"}
                        type="editable-card"
                        activeKey={activeLanguageTab}
                        hideAdd={filteredcomponentsTabs.length === 2}
                        onEdit={onEdit}
                        animated={false}
                        onChange={async activeKey => {
                          setActiveLanguageTab(activeKey as Language);
                          if (!uploadForm) return;
                          await validateFields({
                            tab: "components",
                          });
                        }}
                      >
                        {filteredcomponentsTabs.map(componentTab => {
                          const lang = componentTab.key;
                          const templateFile = files?.[lang];
                          const { filteredLayers, setFilteredLayers } =
                            data[componentTab.key] ?? {};
                          return (
                            <Tabs.TabPane
                              tab={componentTab.label}
                              key={componentTab.key}
                              closable={
                                filteredcomponentsTabs.length === 2 &&
                                !drawerDisabledMap?.files[lang]
                              }
                            >
                              <DisabledFieldsAlert
                                visible={drawerDisabledMap?.files[lang]}
                              />
                              <UploadDragger
                                emptyFileError={emptyFileError[lang]}
                                fileList={
                                  templateFile
                                    ? [toUploadFile(templateFile)!]
                                    : []
                                }
                                allowDeletion={!drawerDisabledMap?.files[lang]}
                                isEditing={templateFile !== undefined}
                                selectedFile={templateFile}
                                setSelectedFile={file => {
                                  setFiles(prev => ({
                                    ...prev,
                                    [lang]: file,
                                  }));
                                  setEmptyFileError(prev => ({
                                    ...prev,
                                    [lang]: false,
                                  }));
                                }}
                                setHtml={file => {
                                  setFiles(prev => ({
                                    ...prev,
                                    [lang]: !prev?.[lang]
                                      ? prev[lang]
                                      : {
                                          ...prev[lang],
                                          html: file?.html,
                                          thumbnail: file?.thumbnail,
                                        },
                                  }));
                                  setEmptyFileError(prev => ({
                                    ...prev,
                                    [lang]: false,
                                  }));
                                }}
                              />
                              {templateFile?.status === "uploading" && (
                                <Skeleton active />
                              )}
                              {templateFile?.status === "done" && (
                                <>
                                  <ComponentsFields
                                    active={activeLanguageTab === lang}
                                    lang={lang}
                                  />
                                  <Divider style={{ margin: 0 }} />
                                  {templateFile.type === "pdf" && (
                                    <div className={styles.pdfTip}>
                                      <div>
                                        Note: This Template is a
                                        non-customizable PDF file that cannot be
                                        customized by agents.
                                      </div>

                                      <div>
                                        Add the Expiration Date and Form Number
                                        as listed at the bottom of the PDF.
                                      </div>
                                    </div>
                                  )}

                                  {templateFile.type !== "pdf" && (
                                    <Layers
                                      selectedFile={activeFile}
                                      disabled={drawerDisabledMap?.files[lang]}
                                      layers={filteredLayers ?? []}
                                      language={lang}
                                      smartVariablesError={
                                        requiredVariablesError[lang]
                                      }
                                      onChange={(layer, action) => {
                                        const { type, data } = action || {};
                                        if (
                                          type === "convert_to_group" ||
                                          type === "convert_to_text"
                                        ) {
                                          setFilteredLayers?.(prevLayers => {
                                            return prevLayers.map(l => {
                                              if (l.id !== layer.id) return l;

                                              if (type === "convert_to_group") {
                                                return toOtherGroupLayer(layer);
                                              } else {
                                                return toTextLayer(layer);
                                              }
                                            });
                                          });
                                        } else if (
                                          type === "group_set_conditional"
                                        ) {
                                          setFilteredLayers?.(prevLayers => {
                                            return prevLayers.map(l => {
                                              if (l.id !== layer.id) return l;
                                              const {
                                                conditionalSet,
                                                isSublayer,
                                                subLayer,
                                              } = data || {};
                                              if (isSublayer) {
                                                return {
                                                  ...l,
                                                  subLayers: (
                                                    l as OtherGroupLayer
                                                  ).subLayers.map(subL => {
                                                    return subL.id ===
                                                      subLayer?.id
                                                      ? toConditionalGroupLayer(
                                                          subL,
                                                          conditionalSet,
                                                        )
                                                      : subL;
                                                  }),
                                                };
                                              }
                                              return toConditionalGroupLayer(
                                                layer,
                                                conditionalSet,
                                              );
                                            });
                                          });
                                        } else if (
                                          type === "group_set_options"
                                        ) {
                                          setFilteredLayers?.(prevLayers => {
                                            return prevLayers.map(l => {
                                              if (l.id !== layer.id) return l;

                                              const { options } = data || {};
                                              return toOptionGroupLayer(
                                                layer,
                                                options,
                                              );
                                            });
                                          });
                                        } else if (
                                          type === "update_snippet_group"
                                        ) {
                                          setFilteredLayers?.(prevLayers => {
                                            return prevLayers.map(l => {
                                              if (l.id !== layer.id) return l;
                                              return {
                                                ...layer,
                                              };
                                            });
                                          });
                                        } else if (
                                          type === "update_smart_variable"
                                        ) {
                                          setFilteredLayers?.(prevLayers => {
                                            return prevLayers.map(l => {
                                              if (l.id !== layer.id) return l;
                                              return {
                                                ...layer,
                                              };
                                            });
                                          });
                                        }
                                        if (
                                          action?.type ===
                                            "update_smart_variable" &&
                                          [
                                            "expirationDate",
                                            "formNumber",
                                          ].includes(layer.smartVariable ?? "")
                                        ) {
                                          validateSmartVariable(layer);
                                        }
                                      }}
                                      onLayerLock={(layer, lock) =>
                                        setFilteredLayers?.(prevLayers =>
                                          prevLayers.map(l =>
                                            l.id !== layer.id
                                              ? l
                                              : { ...l, isLocked: lock },
                                          ),
                                        )
                                      }
                                      onSearch={onSearchLayersBy}
                                      value={searchLayersBy}
                                    />
                                  )}
                                </>
                              )}
                            </Tabs.TabPane>
                          );
                        })}
                      </Tabs>
                    )}
                  </Form>
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
        <div className={styles.previewContainer}>
          {languages.map(lang => (
            <TemplateRenderDataProvider
              key={lang}
              file={files[lang]}
              material={undefined}
            >
              <Preview
                lang={lang}
                isActive={activeLanguageTab === lang}
                helpTexts={
                  activeTab === "settings"
                    ? {
                        idle: "", // File upload will be done in "components" tab. So we should remove help text within preview section in "settings" tab.
                      }
                    : undefined
                }
              />
            </TemplateRenderDataProvider>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default FileDrawer;
