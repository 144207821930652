import { Typography } from "antd";
import { usePortalExportContext } from "./PortalExportContext";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { PortalConfigurationSelect } from "./PortalConfigurationSelect";
import { usePortalExportData } from "./usePortalExportData";
import { useColumnValidation } from "./useColumnValidation";

export const PortalConfigurationFields = () => {
  const { formValues, updateFormValues } = usePortalExportContext();
  const { columns: feedColumns } = useFeedContext();
  const { brands, accounts, loading } = usePortalExportData();
  const { validateBrandsColumnQuery, validateAccountsColumnQuery } =
    useColumnValidation();

  const { brandId, accountId, brandColumn, accountColumn } = formValues;

  const handleBrandChange = (value: string | undefined) => {
    updateFormValues({ brandId: value, brandColumn: "" });
  };

  const handleAccountChange = (value: string | undefined) => {
    updateFormValues({ accountId: value, accountColumn: "" });
  };

  const handleBrandsColumnChange = async (brandColumn: string) => {
    try {
      updateFormValues({ brandColumn });
    } catch (error) {
      updateFormValues({ brandColumn: "" });
    }
  };

  const handleAccountsColumnChange = async (accountColumn: string) => {
    try {
      updateFormValues({ accountColumn });
    } catch (error) {
      updateFormValues({ accountColumn: "" });
    }
  };

  return (
    <>
      <Typography.Text strong>
        Add tags to the assets to find them in the portal using filters
      </Typography.Text>

      <PortalConfigurationSelect
        label="Brand"
        optional
        disabled={loading || validateBrandsColumnQuery.isLoading}
        options={brands}
        value={brandId}
        columnValue={brandColumn}
        onChange={handleBrandChange}
        onColumnChange={handleBrandsColumnChange}
        feedColumns={feedColumns}
        columnValidationError={validateBrandsColumnQuery.data === false}
      />

      <PortalConfigurationSelect
        label="Account"
        disabled={loading || validateAccountsColumnQuery.isLoading}
        options={accounts}
        value={accountId}
        columnValue={accountColumn}
        onChange={handleAccountChange}
        onColumnChange={handleAccountsColumnChange}
        feedColumns={feedColumns}
        columnValidationError={validateAccountsColumnQuery.data === false}
      />
    </>
  );
};
