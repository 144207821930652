import { Row, Typography, Form, Radio, Skeleton } from "antd";
import moment from "moment";
import { useCallback } from "react";
import { FedexShippingMethods as FedexShippingData } from "shared/types/marketingMaterials";
import styles from "./FedexShippingMethods.module.scss";

const FedexShippingMethods = ({
  shippingData,
  isLoading,
}: {
  shippingData?: FedexShippingData[];
  isLoading?: boolean;
}) => {
  const getReadableDeliveryDate = useCallback((deliveryDate: string) => {
    const date = moment(deliveryDate);
    const formattedDate = date.format("dddd MMM D YYYY");
    return ` Receive it by ${formattedDate}`;
  }, []);

  if (!shippingData && !isLoading) return null;
  return (
    <Row className={styles.shippingRow}>
      {isLoading && <Skeleton active />}
      {!!shippingData && !isLoading && (
        <>
          <Typography.Title level={4}>Shipping Method</Typography.Title>
          <Form.Item name="shippingMethod">
            <Radio.Group>
              {shippingData.map(method => (
                <Radio key={method.serviceType} value={method}>
                  ${method.totalFedexCharge} {method.serviceName} -
                  {getReadableDeliveryDate(method.deliveryDate)}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <p>
            **Pricing is estimated. Final cost will be provided by Communicorp
            upon the creation of the Shipping Label.
          </p>
        </>
      )}
    </Row>
  );
};

export default FedexShippingMethods;
