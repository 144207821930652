import styles from "./PortalConfiguration.module.scss";
import { PortalConfigurationTable } from "./PortalConfigurationTable";
import { PortalConfigurationFields } from "./PortalConfigurationFields";

export const PortalConfiguration = () => (
  <div className={styles.portalConfiguration}>
    <PortalConfigurationFields />
    <PortalConfigurationTable />
  </div>
);
