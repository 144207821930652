import { CSSProperties } from "react";
import AflacLogo from "statics/images/aflac-logo-white.jpeg";

export interface IProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
}

export const AflacIcon = ({
  width = "72px",
  height = "24px",
  style,
}: IProps) => {
  return <img src={AflacLogo} width={width} height={height} style={style} />;
};
