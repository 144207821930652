import { useV3Query } from "./useV3Query";
import { ClientFragment } from "../../../graphql/v3.gen";

export const useV3Client = () => {
  const orgId = process.env.REACT_APP_AUTH_ORGANIZATION;

  const { data, loading, error } = useV3Query<{ clients: ClientFragment[] }>({
    variables: {
      input: {
        query: "v2GetClientByOrgId",
        variables: {
          orgId,
        },
      },
    },
  });

  // When on av3-dev (V2) the orgId will match many V3 clients, so we need to filter by name
  const isAv3Dev = orgId === "org_9b0KBRXyFWMRaMMy";
  const client = isAv3Dev
    ? data?.v2AccessQueryExt?.clients.find(
        client => client.name === "AV3 (dev)",
      )
    : data?.v2AccessQueryExt?.clients[0];

  return {
    loading,
    error,
    client,
  };
};
