import React, { useContext, useState } from "react";

interface FormValueState {
  brandId: string | undefined;
  brandColumn: string;
  accountId: string | undefined;
  accountColumn: string;
  feedColumns: string[];
}

interface PortalExportContextType {
  formValues: FormValueState;
  updateFormValues: (partialState: Partial<FormValueState>) => void;
}

const PortalExportContext = React.createContext<
  PortalExportContextType | undefined
>(undefined);

export const PortalExportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [formValues, setFormValues] = useState<FormValueState>({
    brandId: undefined,
    brandColumn: "",
    accountId: undefined,
    accountColumn: "",
    feedColumns: [],
  });

  const updateFormValues = (partialState: Partial<FormValueState>) => {
    setFormValues(prevState => ({ ...prevState, ...partialState }));
  };

  const contextValue: PortalExportContextType = {
    formValues,
    updateFormValues,
  };

  return (
    <PortalExportContext.Provider value={contextValue}>
      {children}
    </PortalExportContext.Provider>
  );
};

PortalExportProvider.displayName = "PortalExportProvider";

export const usePortalExportContext = () => {
  const portalExportContext = useContext(PortalExportContext);
  if (!portalExportContext) {
    throw new Error(
      "usePortalExportContext must be used within a PortalExportProvider",
    );
  }
  return portalExportContext;
};
