import API from "services";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { usePortalExportContext } from "./PortalExportContext";
import { useQuery } from "react-query";
import { usePortalExportData } from "./usePortalExportData";

export const useColumnValidation = () => {
  const { feedId, selectedRows } = useFeedContext();
  const { formValues } = usePortalExportContext();
  const { brands, accounts, loading } = usePortalExportData();
  const validAccounts = accounts?.map(account => account.name);
  const validBrands = brands?.map(brand => brand.name);
  const { brandColumn, accountColumn } = formValues;

  const validateColumn = async (columnName: string, validValues: string[]) => {
    try {
      const { result: isValid } =
        await API.services.assetExporter.validateFeedColumnValues({
          feedId,
          columnName,
          rowIdentifiers: selectedRows,
          validValues,
        });

      return isValid;
    } catch (error) {
      return false;
    }
  };

  const validateBrandsColumnQuery = useQuery(
    ["validateBrandsColumn", brandColumn],
    () => validateColumn(brandColumn, validBrands),
    {
      enabled: !!brandColumn && !loading && !!validBrands,
    },
  );

  const validateAccountsColumnQuery = useQuery(
    ["validateAccountsColumn", accountColumn],
    () => validateColumn(accountColumn, validAccounts),
    {
      enabled: !!accountColumn && !loading && !!validAccounts,
    },
  );

  return {
    validateBrandsColumnQuery,
    validateAccountsColumnQuery,
  };
};
