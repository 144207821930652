import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";
import { RowType, PortalConfigurationTableData } from "../types";
import { FeedTblCol } from "shared/types/assetExporter";

export const getPortalConfigurationTableData = (
  feedColumns: FeedTblCol[],
): PortalConfigurationTableData[] => {
  const assetRowData = {
    title: "asset",
    rowType: RowType.Media,
  };

  const rowData = feedColumns
    .filter(
      feedColumn =>
        typeof feedColumn.title === "string" &&
        !!feedColumn.title &&
        feedColumn.key !== COLUMN_ASSET_BATCHES,
    )
    .map(feedColumn => ({
      title: typeof feedColumn.title === "string" ? feedColumn.title : "",
      rowType: RowType.Feed,
    }));

  return [assetRowData, ...rowData];
};
