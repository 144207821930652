import { Select, Typography } from "antd";
import styles from "./PortalConfiguration.module.scss";
import { FeedTblCol } from "shared/types/assetExporter";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";

interface Option {
  id: string;
  name: string;
}

interface PortalConfigurationSelectProps {
  label: string;
  optional?: boolean;
  disabled: boolean;
  options?: Option[];
  value: string | undefined;
  columnValue: string | undefined;
  onChange: (value: string | undefined) => void;
  onColumnChange: (value: string) => void;
  feedColumns: FeedTblCol[];
  columnValidationError: boolean;
}

const PortalColumnSelect = ({
  feedColumns,
  isVisible,
  onChange,
  disabled,
  value,
  columnValidationError,
  columnType,
}: {
  feedColumns: FeedTblCol[];
  isVisible: boolean;
  onChange: (value: string) => void;
  disabled: boolean;
  value: string | undefined;
  columnValidationError: boolean;
  columnType: string;
}) => {
  const feedColumnOptions = feedColumns
    .filter(
      column =>
        !!column.key && !!column.title && column.key !== COLUMN_ASSET_BATCHES,
    )
    .map(column => ({
      label: column.title,
      value: column.key,
    }));

  if (!isVisible) return null;
  return (
    <div className={styles.columnSelectWrapper}>
      <div>
        <Typography.Text>Feed Column</Typography.Text>
      </div>
      <div>
        <Select
          className={styles.select}
          onChange={value => onChange(String(value))}
          disabled={disabled}
          value={value}
        >
          {feedColumnOptions.map(feedColumn => {
            return (
              <Select.Option key={feedColumn.value} value={feedColumn.value}>
                {feedColumn.label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {columnValidationError && (
        <Typography.Text type="danger">{`The values in column: ${value} contain invalid ${columnType.toLowerCase()} values.`}</Typography.Text>
      )}
    </div>
  );
};

export const PortalConfigurationSelect: React.FC<
  PortalConfigurationSelectProps
> = ({
  label,
  optional,
  disabled,
  options,
  value,
  columnValue,
  onChange,
  onColumnChange,
  feedColumns,
  columnValidationError,
}) => (
  <div>
    <div>
      <Typography.Text>{label}</Typography.Text>
      {optional && (
        <Typography.Text type="secondary"> (optional)</Typography.Text>
      )}
    </div>
    <div className={styles.selectContainer}>
      <Select
        disabled={disabled}
        className={styles.select}
        onChange={onChange}
        value={value}
        allowClear
      >
        <Select.Option value="">Feed Column</Select.Option>
        {options && options.length > 0 && (
          <Select.OptGroup label={`${label}s`}>
            {options.map(option => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
      </Select>
      <PortalColumnSelect
        feedColumns={feedColumns}
        isVisible={value === ""}
        onChange={onColumnChange}
        disabled={disabled}
        value={columnValue}
        columnValidationError={columnValidationError}
        columnType={label}
      />
    </div>
  </div>
);
