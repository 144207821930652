import { TSortByOption } from "screens/assetBuilder/offers/select/OfferFilterV2";
import GenericError from "shared/errors/GenericError";
import { TRedirectPath } from "shared/hooks/assetBuilder/useRedirect";
import { IDataTableError } from "shared/types/errors";
import { JobStatusString } from "utils/aws/lambda";
import {
  ExtendedObjectType,
  IDimension,
  ITemplate,
  LogoDropZoneType,
  LogoEventType,
} from "./designStudio";
import { IDisclosure } from "./legalLingo";
import { INewOrder } from "./newOrders";
import { FeedOfferKeys, GetPaymentEngineDealDataResult } from "./paymentEngine";
import { OfferType } from "./shared";

export interface IGetRawOfferByVinOfferResponse {
  result?: IGetRawOfferByVinOfferResult;
  error?: {
    message: string;
  };
}

export interface IGetRawOfferByVinOfferResult {
  rawOffer: IRawOfferDataFromService;
  offerEdit: OfferEditFromService;
}

export type OfferEditFromService = Record<SingletonOfferKeys, string> & {
  editedKeys?: Record<SingletonOfferKeys, boolean>;
};

export interface IOfferListResponse {
  result: IOfferList;
  error: {
    message: string;
  };
}
interface IOfferList {
  selectedOfferList: IOffer[];
  offerList: IOffer[];
  flaggedVins: string[];
  total: number;
}

export type OfferFromService = Record<SingletonOfferKeys, string> &
  Record<OfferRebateKeys, string> &
  Record<OfferCustomKeys, string>;

/**
 * This IOffer will be key value pair object.
 * There will be number of keys.
 *  - title
 *  - summary
 *  - lease
 *  - purchase
 *  - finanace
 *  - zeroDown
 */
export interface IOffer {
  [key: string]: IOfferRowObject | string;
}

interface IOfferRowObject {
  header: IOfferData;
  restOfData: IOfferData;
}
interface IOfferData {
  order: string[];
  data: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

export type checkedOfferFilterKeys =
  | "Lease"
  | "Zero Down Lease"
  | "Finance"
  | "Purchase"
  | "APR";

export interface ICheckedOfferFilter {
  Lease: boolean;
  "Zero Down Lease": boolean;
  Finance: boolean;
  Purchase: boolean;
  APR: boolean;
}

type LadTechSingletonOfferKeys =
  | "vehicleType"
  | "vehicleCondition"
  | "year"
  | "make"
  | "model"
  | "trim"
  | "driveTrain"
  | "transmission"
  | "cylinders"
  | "feature1"
  | "feature2"
  | "stockNumber"
  | "vin"
  | "msrp"
  | "accessories"
  | "modelCode"
  | "packagesExtended"
  | "numberOfPayments"
  | "monthlyPayment"
  | "transactabilityScore"
  | "totalDueAtSigning"
  | "totalDueAtSigningInclRebates"
  | "leaseVehicleSalesPrice"
  | "capCost"
  | "capCostReduction"
  | "netAdjustedCapitalizedCost"
  | "residualValue"
  | "totalLeaseCharge"
  | "totalPayments"
  | "securityDeposit"
  | "acquisitionFee"
  | "terminationFee"
  | "minFICO"
  | "milesPerYear"
  | "centsPerMile"
  | "additionalLeaseDisclosure"
  | "dealerDiscount"
  | "dealerPrice"
  | "finalPrice"
  | "finalPriceName"
  | "priceAfterConditionalRebates"
  | "invoicePrice"
  | "accountingCost"
  | "modelId"
  | "advertisedPrice"
  | "savingsOffMSRP"
  | "savingsOffMSRPTitle"
  | "savingsOffMSRPDescription"
  | "additionalPurchaseDisclosure"
  | "aprRate"
  | "aprTerm"
  | "aprPayment"
  | "amntFinanced"
  | "financeDownPayment"
  | "financeMinFICO"
  | "zeroDownLeaseNumberOfPayments"
  | "zeroDownLeaseMonthlyPayment"
  | "zeroDownLeaseTotalDueAtSigning"
  | "zeroDownLeaseTotalDueAtSigningInclRebates"
  | "zeroDownLeaseVehicleSalesPrice"
  | "zeroDownLeaseCapCost"
  | "zeroDownLeaseCapCostReduction"
  | "zeroDownLeaseNetAdjustedCapitalizedCost"
  | "zeroDownLeaseResidualValue"
  | "zeroDownLeaseTotalLeaseCharge"
  | "zeroDownLeaseTotalPayments"
  | "zeroDownLeaseSecurityDeposit"
  | "zeroDownLeaseAcquisitionFee"
  | "zeroDownLeaseTerminationFee"
  | "zeroDownLeaseMinFICO"
  | "zeroDownLeaseMilesPerYear"
  | "zeroDownLeaseCentsPerMile"
  | "expirationDate"
  | "dateInStock"
  | "dealerCode"
  | "dealerId"
  | "exteriorColor"
  | "mileage"
  | "imageUrl"
  | "daysInStock"
  | "percentOffMSRP"
  | "aprAmntFinanced"
  | "aprMinFICO"
  | "financeRate"
  | "financeTerm"
  | "financePayment"
  | "dealerName"
  // AV2-2465
  | "capCostIncludesAcqusitionFee"
  | "capCostIncludesFirstMonthPayment"
  | "dasIncludesFirstMonthPayment"
  | "dasIncludesAcqusitionFee"
  | "aprLender"
  | "financeLender"
  | "accessoryPrice"
  // AV2-2792
  | "suggestedDealerContribution"
  // AV2-2863
  | "additionalFinanceDisclosure"
  | "additionalZeroDownLeaseDisclosure"
  | "additionalAPRDisclosure"
  // AV2-2663 new fields added
  | "dmsStatusCode"
  | "zeroDownSuggestedDealerContribution"
  //  AV2-2663 new fields with strings separated by "|" for multiple values
  | "optionCodes"
  | "packageNames"
  | "packageCodes"
  | "imageUrls"
  | "cabStyle"
  // AV2-3335 lender fields added
  | "leaseLender"
  | "zeroDownLeaseLender"
  // AV2-3795: vehicle info fields added
  | "blackBookAverage"
  | "blackBookClean"
  | "kbbRetail"
  | "kbbTradeIn"
  | "nadaRetail"
  | "nadatradeIn"
  | "BlindSpotMonitor"
  | "priorityScore"
  | "leaseDownPayment";

type InternalSingletonOfferKeys =
  | "leaseTerm"
  | "dealerName"
  | "leaseDownPayment"
  | "leaseExpirationDate"
  | "leaseMonthlyPayment"
  | "leaseTotalDueAtSigning"
  | "leaseSellingPrice"
  | "rowIdentifier";

export type SingletonOfferKeys =
  | LadTechSingletonOfferKeys
  | InternalSingletonOfferKeys;

export const isRebateOfferKey = (key: any): key is RepeatableOfferKeys => {
  return (
    typeof key === "string" &&
    /(lease|zerodown|purchase|conditional)rebate(name|disclosure)?/i.test(key)
  );
};

export type RepeatableOfferKeys =
  | "conditionalRebate"
  | "conditionalRebateDisclosure"
  | "conditionalRebateName"
  | "leaseRebate"
  | "leaseRebateDisclosure"
  | "leaseRebateName"
  | "leaseConditionalRebate"
  | "leaseConditionalRebateDisclosure"
  | "leaseConditionalRebateName"
  | "purchaseRebate"
  | "purchaseRebateDisclosure"
  | "purchaseRebateName"
  | "zeroDownLeaseRebate"
  | "zeroDownLeaseRebateName"
  | "zeroDownLeaseRebateDisclosure"
  | "zeroDownLeaseConditionalRebate"
  | "zeroDownLeaseConditionalRebateName"
  | "zeroDownLeaseConditionalRebateDisclosure"
  | "financeConditionalRebate"
  | "financeConditionalRebateName"
  | "financeConditionalRebateDisclosure"
  | "leaseCustom"
  | "zeroDownLeaseCustom"
  | "purchaseCustom"
  | "financeCustom"
  | "aprCustom";

// up to 6 <offerType>n<Name or Disclosure>
export type OfferRebateKeys =
  | "conditionalRebate1"
  | "conditionalRebate1Disclosure"
  | "conditionalRebate1Name"
  | "leaseRebate1"
  | "leaseRebate1Disclosure"
  | "leaseRebate1Name"
  | "leaseConditionalRebate1"
  | "leaseConditionalRebate1Disclosure"
  | "leaseConditionalRebate1Name"
  | "purchaseRebate1"
  | "purchaseRebate1Disclosure"
  | "purchaseRebate1Name"
  | "financeConditionalRebate1"
  | "financeConditionalRebate1Disclosure"
  | "financeConditionalRebate1Name"
  | "zeroDownLeaseRebate1"
  | "zeroDownLeaseRebate1Name"
  | "zeroDownLeaseRebate1Disclosure"
  | "zeroDownLeaseConditionalRebate1"
  | "zeroDownLeaseConditionalRebate1Name"
  | "zeroDownLeaseConditionalRebate1Disclosure"
  | "conditionalRebate2"
  | "conditionalRebate2Disclosure"
  | "conditionalRebate2Name"
  | "leaseRebate2"
  | "leaseRebate2Disclosure"
  | "leaseRebate2Name"
  | "leaseConditionalRebate2"
  | "leaseConditionalRebate2Disclosure"
  | "leaseConditionalRebate2Name"
  | "purchaseRebate2"
  | "purchaseRebate2Disclosure"
  | "purchaseRebate2Name"
  | "financeConditionalRebate2"
  | "financeConditionalRebate2Disclosure"
  | "financeConditionalRebate2Name"
  | "zeroDownLeaseRebate2"
  | "zeroDownLeaseRebate2Name"
  | "zeroDownLeaseRebate2Disclosure"
  | "zeroDownLeaseConditionalRebate2"
  | "zeroDownLeaseConditionalRebate2Name"
  | "zeroDownLeaseConditionalRebate2Disclosure"
  | "conditionalRebate3"
  | "conditionalRebate3Disclosure"
  | "conditionalRebate3Name"
  | "leaseRebate3"
  | "leaseRebate3Disclosure"
  | "leaseRebate3Name"
  | "leaseConditionalRebate3"
  | "leaseConditionalRebate3Disclosure"
  | "leaseConditionalRebate3Name"
  | "purchaseRebate3"
  | "purchaseRebate3Disclosure"
  | "purchaseRebate3Name"
  | "financeConditionalRebate3"
  | "financeConditionalRebate3Disclosure"
  | "financeConditionalRebate3Name"
  | "zeroDownLeaseRebate3"
  | "zeroDownLeaseRebate3Name"
  | "zeroDownLeaseRebate3Disclosure"
  | "zeroDownLeaseConditionalRebate3"
  | "zeroDownLeaseConditionalRebate3Name"
  | "zeroDownLeaseConditionalRebate3Disclosure"
  | "conditionalRebate4"
  | "conditionalRebate4Disclosure"
  | "conditionalRebate4Name"
  | "leaseRebate4"
  | "leaseRebate4Disclosure"
  | "leaseRebate4Name"
  | "leaseConditionalRebate4"
  | "leaseConditionalRebate4Disclosure"
  | "leaseConditionalRebate4Name"
  | "purchaseRebate4"
  | "purchaseRebate4Disclosure"
  | "purchaseRebate4Name"
  | "financeConditionalRebate4"
  | "financeConditionalRebate4Disclosure"
  | "financeConditionalRebate4Name"
  | "zeroDownLeaseRebate4"
  | "zeroDownLeaseRebate4Name"
  | "zeroDownLeaseRebate4Disclosure"
  | "zeroDownLeaseConditionalRebate4"
  | "zeroDownLeaseConditionalRebate4Name"
  | "zeroDownLeaseConditionalRebate4Disclosure"
  | "conditionalRebate5"
  | "conditionalRebate5Disclosure"
  | "conditionalRebate5Name"
  | "leaseRebate5"
  | "leaseRebate5Disclosure"
  | "leaseRebate5Name"
  | "leaseConditionalRebate5"
  | "leaseConditionalRebate5Disclosure"
  | "leaseConditionalRebate5Name"
  | "purchaseRebate5"
  | "purchaseRebate5Disclosure"
  | "purchaseRebate5Name"
  | "financeConditionalRebate5"
  | "financeConditionalRebate5Disclosure"
  | "financeConditionalRebate5Name"
  | "zeroDownLeaseRebate5"
  | "zeroDownLeaseRebate5Name"
  | "zeroDownLeaseRebate5Disclosure"
  | "zeroDownLeaseConditionalRebate5"
  | "zeroDownLeaseConditionalRebate5Name"
  | "zeroDownLeaseConditionalRebate5Disclosure"
  | "conditionalRebate6"
  | "conditionalRebate6Disclosure"
  | "conditionalRebate6Name"
  | "leaseRebate6"
  | "leaseRebate6Disclosure"
  | "leaseRebate6Name"
  | "leaseConditionalRebate6"
  | "leaseConditionalRebate6Disclosure"
  | "leaseConditionalRebate6Name"
  | "purchaseRebate6"
  | "purchaseRebate6Disclosure"
  | "purchaseRebate6Name"
  | "financeConditionalRebate6"
  | "financeConditionalRebate6Disclosure"
  | "financeConditionalRebate6Name"
  | "zeroDownLeaseRebate6"
  | "zeroDownLeaseRebate6Name"
  | "zeroDownLeaseRebate6Disclosure"
  | "zeroDownLeaseConditionalRebate6"
  | "zeroDownLeaseConditionalRebate6Name"
  | "zeroDownLeaseConditionalRebate6Disclosure";

// up to 5 <offerType>Custom<n>
type LeaseCustomKeys =
  | "leaseCustom1"
  | "leaseCustom2"
  | "leaseCustom3"
  | "leaseCustom4"
  | "leaseCustom5";

type ZeroDownLeaseCustomKeys =
  | "zeroDownLeaseCustom1"
  | "zeroDownLeaseCustom2"
  | "zeroDownLeaseCustom3"
  | "zeroDownLeaseCustom4"
  | "zeroDownLeaseCustom5";

type PurchaseCustomKeys =
  | "purchaseCustom1"
  | "purchaseCustom2"
  | "purchaseCustom3"
  | "purchaseCustom4"
  | "purchaseCustom5";

type FinanceCustomKeys =
  | "financeCustom1"
  | "financeCustom2"
  | "financeCustom3"
  | "financeCustom4"
  | "financeCustom5";

type APRCustomKeys =
  | "aprCustom1"
  | "aprCustom2"
  | "aprCustom3"
  | "aprCustom4"
  | "aprCustom5";

export type OfferCustomKeys =
  | LeaseCustomKeys
  | ZeroDownLeaseCustomKeys
  | PurchaseCustomKeys
  | FinanceCustomKeys
  | APRCustomKeys;

export const isCustomOfferKey = (key: any): key is OfferCustomKeys => {
  return (
    typeof key === "string" &&
    /(lease|zeroDownLease|purchase|finance|apr)Custom/i.test(key)
  );
};

export type AprOfferKey = "aprRate" | "aprTerm" | "aprAmntFinanced";
export type DealerDiscountKey = "msrp" | "dealerPrice" | "accessoryPrice";

type GeneratedOfferKeys = "daysInStock";

export interface IRawOfferDataFromService {
  hash: string;
  vin: string;
  updated?: number;
  editedKeys?: Record<
    | SingletonOfferKeys
    | RepeatableOfferKeys
    | OfferRebateKeys
    | OfferCustomKeys,
    boolean
  >;
  row: OfferFromService;
}

export interface ISortingOption {
  content: string;
  desc: boolean;
  key: SingletonOfferKeys;
}

export interface IProcessedOfferData {
  hash: string;
  vin: string;
  updated?: number;
  editedKeys?: Record<
    SingletonOfferKeys | RepeatableOfferKeys | OfferRebateKeys,
    boolean
  >;
  row: Record<SingletonOfferKeys, string> &
    Record<RepeatableOfferKeys, string[]>;
}

export type RawOfferData = Record<SingletonOfferKeys, string> &
  Record<RepeatableOfferKeys, string[]>;
export type OfferData = RawOfferData & Record<GeneratedOfferKeys, string>;

export type OfferValidationErrors = RawOfferData;

export type IOfferDataValidationDescription = Partial<
  Record<
    SingletonOfferKeys | RepeatableOfferKeys,
    (value: string) => string | null
  >
>;
export type IOfferDataGenerationDescription = Record<
  GeneratedOfferKeys,
  (data: RawOfferData) => string
>;

export interface IQueryParameters {
  // Examples of possible keys and their value types
  // currentPage: number;
  // searchBy?: string;
  // offerTypes?: string; // comma separated string
  // sortBy?: string
  // vehicleCondition?: string
  [key: string]:
    | string
    | number
    | ISortingOption[]
    | ICheckedOfferFilter
    | OfferType[];
}

export type TOfferListQueryParameters = {
  [key in
    | TOfferFilterField
    | "feedId"
    | "currentPage"
    | "dealerCode"
    | "vins"
    | "searchBy"
    | "filterField"]?: TFetchOfferListValue | number | string | string[];
};
export type TGetOfferListResult<L = TGetOfferListItem> = {
  total: number;
  offerList: L[];
  editedVins?: string[];
  flaggedVins?: string[];
};
export type TGetOfferListItem<I = OfferFromService> = {
  editedPairs: Record<
    keyof OfferData | RepeatableOfferKeys | OfferRebateKeys,
    string
  >;
  updated: number;
  row: I;
};

export type TOfferFilterField = "vehicleCondition" | "offerTypes" | "sortBy";
type TFetchOfferListValue = TVehicleCondition | OfferType[] | TSortByOption[];
export type TVehicleCondition = "New" | "Used" | "CPO" | "All";

interface IGetWFTemplatesResult {
  templateList: Array<{ templateName: string; templateID: string }>;
}

export interface IGetWFTemplatesResponse {
  result: null | IGetWFTemplatesResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IGetWFFoldersResult {
  res: Array<{ name: string; id: string }>;
}

export interface IGetWFFoldersResponse {
  result: null | IGetWFFoldersResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IProofExportDataResult {
  orderData: IWorkfrontProofData[];
}

export interface IProofExportDataResponse {
  result: null | IProofExportDataResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IGetPaymentEngineDataResult {
  res: GetPaymentEngineDealDataResult;
}

export interface IGetPaymentEngineDataResponse {
  result: null | IGetPaymentEngineDataResult;
  error: null | GenericError;
  statusCode: number;
  message?: string;
}

export type ProcessedPaymentEngineData = Record<FeedOfferKeys, string>;

export interface IOfferDataSection {
  title: string;
  header: OfferDataRow[];
  body: OfferDataRow[];
  offerType?: OfferType;
}

export type OfferDataRow =
  | {
      fields: Array<OfferDataField<SingletonOfferKeys | GeneratedOfferKeys>>;
      repeatable?: false;
    }
  | {
      fields: Array<OfferDataField<RepeatableOfferKeys>>;
      repeatable: true;
    };

export interface IOfferDataProps {
  field?: keyof OfferData;
  title: string;
  value?: string;
  className?: string;
  edited: boolean;
  originalValue?: string | number;
}

export interface IOfferDataInputProps extends IOfferDataProps {
  error?: string;
  help?: string;
  onChange?: (value: string) => void;
  originalValue?: string | number;
  savedEditedValue?: string | number;
  enablePaymentEngine?: boolean;
  valueChanged?: boolean;
  getRawOfferByVinError?: GenericError | null;
  isPaymentEngineField?: boolean;
  togglePaymentEngineModal?: (
    initialSelectedRow: OfferEditFieldObject | null,
  ) => void;
}

export type OfferEditFieldObject = {
  field: keyof OfferData;
  title: string;
  value: string | number;
  vin?: string;
};

interface IOfferDataField {
  title: string;
  colspan?: number;
  Component: React.ComponentType<IOfferDataProps>;
}

interface IOfferDataGeneratedField extends IOfferDataField {
  key: GeneratedOfferKeys;
}
interface IOfferDataEditableField<
  Key extends RepeatableOfferKeys | SingletonOfferKeys,
> extends IOfferDataField {
  key: Key;
  Input: React.ComponentType<IOfferDataInputProps>;
  help?: string;
}

export type OfferDataField<
  Key extends SingletonOfferKeys | RepeatableOfferKeys | GeneratedOfferKeys,
> = Key extends RepeatableOfferKeys | SingletonOfferKeys
  ? IOfferDataEditableField<Key>
  : IOfferDataGeneratedField;

export const isEditableField = (
  field: any,
): field is IOfferDataEditableField<
  RepeatableOfferKeys | SingletonOfferKeys
> => {
  return field.hasOwnProperty("Input");
};

export type RawSelectedOffers = Record<
  string,
  {
    offerData: OfferData;
    offerIndex: Record<OfferType, boolean>;
  }
>;

export interface ISelectedOffer {
  offerData: OfferData;
  offers: OfferType[];
  flagged?: boolean;
}

export interface IPDFDisclosureParams {
  assetType: string;
  idx: number;
  dimension: string;
  vin: string;
  disclosure: IDisclosure;
}

export interface IPDFCustomDisclosure extends Partial<IPDFDisclosureParams> {
  cleanedText: string;
}

export interface IPDFData {
  canvasID: string;
  pdfOfferData: {
    year: string;
    make: string;
    model: string;
    trim: string;
    selectedOffers: string;
    disclaimer: string;
  };
}

export interface IAssetInstance {
  id: string;
  template: ITemplate | null;
  offers: Record<
    string,
    {
      offerData: OfferData;
      offerTypes: Record<OfferType, boolean>;
    }
  >;
  visibilities?: IAssetBuildInstance["visibilities"];
  logoSubstitutions?: IAssetBuildInstance["logoSubstitutions"];
  lifestyleImageUrl?: IAssetBuildInstance["lifestyleImageUrl"];
  lifestyleImageName?: IAssetBuildInstance["lifestyleImageName"];
  lifestyleFabricImageJson?: IAssetBuildInstance["lifestyleFabricImageJson"];
  originalIndexOrder?: number;
  imageDataUrl?: string;
  isCustomImage?: boolean;
  disclosure?: {
    allSizes?: IDisclosure;
    currentSize?: IDisclosure;
    selectedAsset?: IDisclosure;
  };
}

export type AssetInstanceRecord = Record<
  string,
  Record<string, IAssetInstance[]>
>;

export interface ICoopInstruction {
  oem: string;
  compliance_site: string;
  instructions: string;
}

export interface IOfferItem {
  row: OfferData;
  updated?: number;
  editedKeys: Record<string, boolean>;
}

export interface ICanvasObject {
  type: string;
  customType?: string;
  customData?: {
    [key: string]: string | number;
  };
  version: string;
  originX: string;
  originY: string;
  left: number;
  width: number;
  top: number;
  height: number;
  selectable?: boolean;
  name?: string;
  fill: string;
  filters: string[];
  stroke: null;
  strokeWidth: number;
  strokeDashArray: null;
  strokeLineCap: "butt";
  strokeDashOffset: number;
  strokeLineJoin: "miter";
  strokeMiterLimit: number;
  scaleX: number;
  scaleY: number;
  angle: number;
  flipX: boolean;
  flipY: boolean;
  opacity: number;
  shadow: null;
  visible: boolean;
  clipTo: null;
  backgroundColor: string;
  fillRule: "nonzero";
  paintFirst: "fill";
  globalCompositeOperation: "source-over";
  transformMatrix: null;
  skewX: number;
  skewY: number;
}

const checkHasType =
  <T extends ICanvasObject>(...types: string[]) =>
  (obj: ICanvasObject): obj is T =>
    types.indexOf(obj.type) !== -1;

const checkHasCustomType =
  <T extends ICanvasObject>(...customTypes: string[]) =>
  (obj: ICanvasObject): obj is T =>
    !!obj.customType && customTypes.indexOf(obj.customType) !== -1;

export interface ITextObject extends ICanvasObject {
  type: "textbox" | "text";
  text: string;
  styles: any;
}

export const isText = checkHasType<ITextObject>("text", "textbox");

export interface IImageObject extends ICanvasObject {
  type: "image";
  src: string;
  crossOrigin?: "anonymous";
  cropX: number;
  cropY: number;
}

export const isImage = checkHasType<IImageObject>("image");

export interface IGroupObject extends ICanvasObject {
  type: "group";
  objects: CanvasObject[];
}
export interface IStampObject extends ICanvasObject {
  customType: "stamp";
  customData: {
    stampId: string;
  };
}

export const isStamp = checkHasCustomType<IStampObject>("stamp");

export interface ILogoObject extends ICanvasObject {
  customType: "logo";
  customData: {
    logoEventType: LogoEventType;
    logoDropZoneType: LogoDropZoneType;
  };
}

export const isLogo = checkHasCustomType<ILogoObject>("logo");

export const isDisclosure = checkHasCustomType<ICanvasObject>("disclosure");

export const isCarcut = checkHasCustomType<ICanvasObject>("car_cut");

export const isLifestyleImage = checkHasCustomType<ICanvasObject>("lifestyle");

export const isVideo = checkHasCustomType<ICanvasObject>("selected_video");
export const isThemeBackground =
  checkHasCustomType<ICanvasObject>("theme_background");

export const isGrid = checkHasCustomType<ICanvasObject>("grid");

export type CanvasObject =
  | IGroupObject
  | IStampObject
  | ILogoObject
  | ITextObject
  | IImageObject
  | ICanvasObject;

export interface ICanvasData {
  objects: CanvasObject[];
}

interface IGetCanvasZipUrlResult {
  s3Url: string;
}

export interface IGetCanvasZipUrlResponse {
  result: null | IGetCanvasZipUrlResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IGeneratePDFResult {
  StatusCode?: number;
  pdfRes: {
    pdfFileName: string;
    pdfUrl: string;
  };
}

export interface IGeneratePDFResponse {
  result: null | IGeneratePDFResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface ICoopSubmissionResponse {
  result: null | IGetCanvasZipUrlResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IExportForBatchToS3Response {
  result: null | { status: string };
  error: null | IDataTableError;
  statusCode: number;
}

interface IUploadCanvasImageResult {
  canvasID: string;
}

export interface IExportImageOrVideoResult {
  jobId: string;
  status: string;
  imgExt: string;
}
export interface IUploadCanvasImageResponse {
  result: null | IUploadCanvasImageResult;
  error: null | IDataTableError;
  statusCode: number;
}
export interface IVideoHtmlRes {
  result: null | IExportImageOrVideoResult;
  error: null | IDataTableError;
  statusCode: number;
}
export interface IUploadAssetToWFResult {
  id: string;
}

export interface IUploadAssetToWFResultResponse {
  result: null | IUploadAssetToWFResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IGetStagesAndRecipientsResult {
  stageAndRecipientString: string;
}

export interface IGetStagesAndRecipientsResponse {
  result: null | IGetStagesAndRecipientsResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IPushToProofResult {
  res: string;
}

export interface IPushToProofResponse {
  result: null | IPushToProofResult;
  error: null | IDataTableError;
  statusCode: number;
}

interface IFeedDataToCSVResult {
  s3Url: string;
  key: string;
}

export interface IFeedDataToCSVResponse {
  result: null | IFeedDataToCSVResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IOfferWithDisclosure {
  selectedOffers: string;
  offerData: OfferData;
  finalDisclosure: string;
}

export type InstanceCheckboxDict = Record<string, Record<string, string[]>>;

export interface IExportedAssetStatus {
  jobId: string;
  status: string;
  imgExt: string;
}

export interface IJellybeanData {
  url: string;
  data: {
    body: string;
  };
}

export interface IWorkfrontProofData {
  integrationType: string;
  orderID: string;
  timestamp: string;
  versions: string;
  teamUrl: string;
}

export interface ICurrentProofVersion {
  createdAt: string;
  documentID: string;
  folderID: string;
  id: string;
  parentFileToken: string;
  stageAndRecipientString: string;
  templateID: string;
  proofName: string;
}

export type SelectedInstance = {
  instance: IAssetBuildInstance;
  assetType: string;
  order: number;
  size: string;
};

export interface IAssetBuilderState {
  fetchingOfferList: boolean;
  fetchingSelectedOfferList: boolean;
  isDataEmpty: boolean;
  total?: number;
  currentPage: number;
  selectedOfferList: Array<{ row: OfferData }>;
  offerList: IOfferItem[];
  masterSelectedOfferList: Array<{ row: OfferData }>;
  rawOfferData: RawOfferData | null;
  editedKeys: Record<string, boolean>;
  offerValidationErrors: OfferValidationErrors | null;
  offerData: OfferData | null;
  rawSelectedOffers: RawSelectedOffers;
  selectedOffers: ISelectedOffer[];
  assetInstances: AssetInstanceRecord;
  checkedOfferFilters: {
    Lease: boolean;
    "Zero Down Lease": boolean;
    Finance: boolean;
    Purchase: boolean;
    APR: boolean;
  };
  errorMessage: null | string;
  s3Url: string;
  pdfUrl: string;
  pdfName: string;
  feedPDFUrl: string;
  canvasIDArr: string[];
  processingExport: boolean;
  processingImageExport: boolean;
  generalMessage: string;
  selectedTemplateSizes: Record<string, Record<string, boolean>>;

  sortByFilter: ISortingOption[];
  savedOrder: null | ISavedOrderState;

  redirect?: TRedirectPath;

  assetInstanceCounter: number;
  offersWithDisclosures: IOfferWithDisclosure[];
  disableExport: boolean;

  useUploadedJellyBean: boolean;
  uploadedJellybean: string;

  canvasLoadingOrder: string[];

  jellyBeanBucket: string;

  editMode: boolean;

  proofHQTemplates: Array<{ templateName: string; templateID: string }>;

  wfFolderList: Array<{ name: string; id: string }>;

  offerEditsWereSaved: boolean;

  buildPage: {
    instances: {
      [instanceKey: string]: IAssetBuildInstance;
    };
    selectedInstance: SelectedInstance[];
  };

  processedPaymentEngineData: ProcessedPaymentEngineData | null;
  rawPaymentEngineData: GetPaymentEngineDealDataResult | null;
  getPaymentEngineDataError: GenericError | null;
  jellybeanImages: IJellybeanData[];
  getJellybeanImagesError: GenericError | null;
  gettingJellybeanImages: boolean;

  lifestyleImageUrl?: string;
  lifestyleImageName?: string;

  offerOperationMode: OfferOperationMode;

  searchQuery: string;
  gettingRawOfferByVin: boolean;
  currentRawOffer: IRawOfferDataFromService | null;
  currentOfferEdit: OfferEditFromService | null;
  getRawOfferByVinError: GenericError | null;
  offerEditTrackerObject: OfferEditTracker | null; // AV2-1878: this changes in current session only

  parentFileToken: string;
  documentID: string;
  savedSelectTemplateSearch: string;
  savedSelectImageSearch: string;
  assetInstanceComparator: AssetInstanceRecord;
  offerDataComparator: OfferData | null;
  saveDialog: boolean;
  path: string;
  menuRoute: string;
  assetTypeCount: TAssetTypeCount;
  selectedExportedAssets: InstanceCheckboxDict;
  createWebIntegrationResult: IWebIntegrationInstanceObject;
  getWebIntegrationsResult: IWebIntegrations[];
  launcherImages: string[];
  integratedToPage: boolean;
  vehicleConditionFilter: VehicleConditions;
  flaggedVins: string[];
  missingOfferTypes: string[];
  triggerAssetInstance: boolean;
  executionId?: string;
  exportedAssets?: IExportedAssetStatus[];
  exportAssetsUploaded?: number;
  isExportCompleted: boolean;
  exportDownloadInProgress: boolean;
  pdfJobStatus: JobStatusString;

  wfProofArr?: IWorkfrontProofData[];
  imageExports: IExportImageOrVideoResult[];
  openOfferOverwriteModal: boolean;
  currentTab?: FeedTab;
  orders: {
    creators: string[];
  };
}

export type OfferOperationMode = "CREATE" | "READ" | "UPDATE" | "DELETE";

export interface ISavedOrderState<M = Record<string, any>> {
  orderId: string;
  selectedOrder: INewOrder;
  selectedOffers: ISelectedOffer[] | null;
  assetInstances: AssetInstanceRecord | null;
  canvasData: ICanvasData[] | null;
  currentTab?: FeedTab;
  meta?: M;
}

export type DealerDataFeed = {
  dealerData: {
    dealer_code: string;
    dealer_name: string;
    dealer_oem: string;
    dealer_url: string;
  };
};

export type AssetBuilderVerb = "select" | "build" | "review" | "launcher";

export interface IAssetBuild {
  /**
   * Below two attributes will be used to display choices in
   * Tooltip.tsx
   */
  offers: Array<{
    offerData: OfferData;
    savedOfferTypes: OfferType[]; // getting from savedOrderState
  }>;
  templates: ITemplate[];

  /**
   * assetType is one of the assetTypes from dataManagement.ts
   * size is <width> x <height> format of the artboard size
   */
  instances: Record<string, Record<string, IAssetBuildInstance[]>>; // { [assetType]: { [size]: IAssetBuildInstance } }
}

/**
 * A user has to select offer first from the tooltip.
 * Then, the below selectedOffer will be assigned.
 * One offer and template per IAssetBuildInstance.
 * So if you want to find one instance from the array,
 * refer to the vin. vin will be unique to each offer.
 */
export interface IInstanceSelectedOffer {
  vin: string;
  offerData: OfferData;
  offerTypes: Array<OfferType | "PurchasePlaceholder">;
  purchaseOptions: OfferType[];
  offerSelectSortingTracker?: Array<OfferType | "PurchasePlaceholder">;
}
export interface IAssetBuildInstance {
  id: string;
  template?: ITemplate;
  selectedOffer?: IInstanceSelectedOffer;
  visibilities?: TVisibility[];
  logoSubstitutions?: TLogoSubstitution[];
  lifestyleImageUrl?: string;
  lifestyleFabricImageJson?: string;
  lifestyleImageName?: string;
  originalIndexOrder?: number;
  imageDataUrl?: string;
  isCustomImage?: boolean;
  disclosure?: {
    allSizes?: IDisclosure;
    currentSize?: IDisclosure;
    selectedAsset?: IDisclosure;
  };
}
export type TVisibility = {
  type: ExtendedObjectType;
  subtype?: LogoEventType; // this is only for 'logo' type for now
  id: string;
  order: number;
  isVisible: boolean;
};
export type TLogoSubstitution = {
  type: ExtendedObjectType;
  id: string;
  order: number;
  currentImageUrl: string;
};

export type ExportDestination = "pdf" | "zip" | "workfront" | "coop";
export type ExportType = "all" | "selected";
export type ExportImageType = "png" | "jpeg";
export type ExportVideoType = "mp4" | "gif";

export interface ILifestyleImage {
  id?: string; // id might not be present if new instance
  url?: string; // same reason as above
  make: string;
  trim?: string; // optional
  model?: string;
  year: number;
  type: "lifestyle" | "theme_bg";
  width?: number;
  height?: number;
  name?: string;
  createdBy?: string;
  createdAt?: number;
  oems?: string[];
}

interface INewThemeBackgroundImage {
  type: string;
  width: number;
  height: number;
  name: string;
  createdBy?: string; // has to be optional due to user.email can be undefined
  brands: string[];
  accounts: string[];
  tags: string[];
}

export interface IPreviewSelectedOptions {
  oem: string;
  storeName: string;
  vehicleCondition?: TVehicleCondition;
}

export interface IThemeBackgroundImage extends INewThemeBackgroundImage {
  id: string;
  url: string;
  url25: string;
  url50: string;
  createdAt: number;
}

export type HeaderMatchType =
  | "orders"
  | "offers"
  | "select"
  | "build"
  | "review"
  | "launch";

// Used to identify which field has been edited
export type EditedFieldId =
  | `${CommaField}-${number}-${string}`
  | `${CommaField}-${number}`;

export type OfferEditTracker = Partial<
  Record<
    SingletonOfferKeys | RepeatableOfferKeys | OfferRebateKeys,
    (EditedFieldId | boolean)[]
  >
>;
export type ReviewExportType =
  | "WORKFRONT"
  | "PDF"
  | "ZIP"
  | "CSV"
  | "COOP"
  | "OFFERPDF"
  | "HTML"
  | "";

export type TAssetTypeCount = Record<string, number>;

export type CommaField =
  | SingletonOfferKeys
  | OfferRebateKeys
  | RepeatableOfferKeys;

export interface ILauncherData {
  domain: string;
  integrationID: string;
  integrationStatus: boolean;
  instanceID: string;
  relativePath: string;
  location: string;
  mobileLocation: string;
  positionModifer: string;
  mobilePositionModifer: string;
  type: string;
  images: IImagesLauncherData[];
  instanceStatus: "LIVE" | "PAUSED";
  createdBy: string;
  createdDate: number;
  lastModifiedBy: string;
  lastModifiedDate: number;
  launchLabel?: string;
  startDate?: number | null;
  endDate?: number | null;
  manualPause?: boolean;
  embedAsHTML?: boolean;
  canvasData?: ICanvasBody[];
  schemaInfo?: {
    modelDate: string;
    vin: string;
    name: string;
    price: string;
    currency: string;
  }[];
  offerImpressions?: {
    event: string;
    num_of_offers: string;
    offerImpression_type: string;
    offerImpression_year: string;
    offerImpression_make: string;
    offerImpression_model: string;
    offerImpression_trim: string;
    offerImpression_body: string;

    offerImpression_price: string;
    offerImpression_term: string;
    offerImpression_storeName: string;
    offerImpression_storeId: string;

    offerImpression_dueSigning: string;
    offerImpression_theme: string;
    offerImpression_salesEvent: string;
    offerImpression_positionPreset: string;
    offerImpression_layout: string;

    offerClick_type: string;
    offerClick_year: string;
    offerClick_make: string;
    offerClick_model: string;
    offerClick_trim: string;
    offerClick_body: string;

    offerClick_price: string;
    offerClick_term: string;
    offerClick_storeName: string;
    offerClick_storeId: string;

    offerClick_dueSigning: string;
    offerClick_theme: string;
    offerClick_salesEvent: string;
    offerClick_positionPreset: string;
    offerClick_layout: string;

    navigationInteraction_action: string;
  }[];
  seoText?: string[];
  isAutoScrollEnabled?: boolean;
  transitionTime?: string;
  timeUntilNextTransition?: string;
}
export interface IStatusModalLauncherData {
  domain: string;
  integrationID: string;
  integrationStatus: boolean;
  instanceID: string;
  relativePath: string;
  location: string;
  mobileLocation: string;
  positionModifer: string;
  mobilePositionModifer: string;
  type: string;
  images: IImagesLauncherData[];
  instanceStatus: "LIVE" | "PAUSED";
  createdBy: string;
  createdDate: number;
  lastModifiedBy: string;
  lastModifiedDate: number;
  launchLabel: string;
  startDate: number;
  endDate: number;
  manualPause: boolean;
}

interface ICanvasBody {
  json: string;
  filename: string;
  dimension: IDimension;
  fonts: string[];
}
export interface IZipExport extends ICanvasBody {
  isVideo: boolean;
  imageOption: ExportImageType | ExportVideoType | "html";
  quality: number;
  dest?: string;
  vin?: string;
}

export interface ICanvasVideo extends ICanvasBody {
  isVideo: boolean;
}

export interface IVideoParams {
  videoDimension: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  imageS3Key: string;
  videoUrl: string;
  isBackgroundVideo: boolean;
  isVideo: true;
  dest?: string;
  vin?: string;
  imageOption: ExportImageType | ExportVideoType | "html";
  filename: string;
}
export interface IZipExportResponse {
  result: IExportedAssetStatus[];
  error: GenericError | null;
}

export interface IZipExportUrlResponse {
  result: {
    zipFileUrl: string;
  };
  error: string | null;
}
export interface ICoopIntegrationData {
  dealerName: string;
  dealerOEM: string;
  primaryCategory: string;
  subCategory: string;
  dealerSiteOnly: string;
  publication: string;
  startDate: string;
  endDate: string;
  resubmission: boolean;
  referenceCode: string;
  ccEmails: string;
  phoneNumber: string;
  coopSite: string;
  mkcOption: string;
  seasonOption: string;
}
export interface IImagesLauncherData {
  imageUrl: string;
  imageAltText: string;
  imageLink: string;
  targetData: string;
  target?: TargetType;
}

export interface IDeleteWebIntegrationResult {
  res: string;
  deletedIntegration: IWebIntegrationInstanceObject;
}

export interface IWebIntegrationInstanceObject {
  domain: string;
  instances: {
    images: {
      imageUrl: string;
      imageAltText: string;
      imageLink: string;
    }[];
    integrationID: string;
    location: string;
    relativePath: string;
    type: LauncherType;
  }[];
}

export interface IWebIntegrations {
  createdBy: string;
  createdDate: number;
  domain: string;
  images: string;
  instanceID: string;
  integrationID: string;
  integrationStatus: boolean;
  location: string;
  relativePath: string;
  mobileLocation: string;
  positionModifer: string;
  mobilePositionModifer: string;
  type: LauncherType;
  instanceStatus: "LIVE" | "PAUSED";
  lastModifiedBy: string;
  lastModifiedDate: number;
  launchLabel?: string;
  startDate?: number | null;
  endDate?: number | null;
  manualPause?: boolean;
}

interface IGetWebIntegrationStatusResult {
  isIntegrated: boolean;
}

export interface IGetWebIntegrationStatusResponse {
  result: null | IGetWebIntegrationStatusResult;
  error: null | IDataTableError;
  statusCode: number;
}

export type LauncherType =
  | "Single"
  | "Grid"
  | "Carousel"
  | "Full Width Carousel"
  | "Stack"
  | "Multi-Image Carousel";

export type TargetType = "_blank" | "_parent";

export type LauncherTabs =
  | "website"
  | "facebook"
  | "email"
  | "display"
  | "social";

export type VehicleConditions = "New" | "Used" | "CPO" | "All";

export type coopBrand =
  | "Honda"
  | "Toyota"
  | "Nissan"
  | "Mazda"
  | "Subaru"
  | "Land Rover"
  | "Jaguar"
  | "Ford"
  | null;

export type FeedTab = {
  feedName: string;
  feedId: string;
  updated: number;
  saved: boolean;
  filterColHeader?: string;
  oemTags?: string[];
  locationTags?: string[];
  storeTags?: string[];
};

export type FeedOffer = OfferData & {
  isVinless: boolean;
  lastUpdated?: number;
  rowIdentifier?: string;
};

export type FeedData = {
  offerList: FeedOffer[];
  total: number;
};

export type GetOfferExists = {
  offerExist: boolean;
};

export type CreateEditOffer = {
  createdOffer: RawOfferData;
};

export type Tab = {
  id: string; // feed id
  name: string;
  filterField: string | null;
  lastUpdate: number;
};

export type MetaFeedMapping = {
  vin: string;
  feedId: string;
};
export enum URLPattern {
  DOMAIN = 0,
  ASSETBUILDER = 1,
  ORDER = 2,
  ORDERID = 3,
}

export type OrdersTableAction =
  | "restore"
  | "edit"
  | "modify"
  | "launcher"
  | "duplicate"
  | "archive";

export type OrderTag = {
  name: string;
};

export type OrderTagResponse<T> = {
  result: T[] | null;
  error: {
    message: string;
  } | null;
};
