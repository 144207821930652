import { Space, Checkbox, Tag, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import styles from "./ExportDrawer.module.scss";
import { ExportDestination } from "./types";
import { getAllAssetBatchIds } from "./utils/getAllAssetBatchIds";
import { getValidAssetBatches } from "./utils/getValidAssetBatches";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { useAdLibraryExportContext } from "./AdLibraryExportContext";

interface AssetBatchSelectionProps {
  HTMLTemplatesMap: Record<string, boolean>;
}

export const AssetBatchSelection = ({
  HTMLTemplatesMap,
}: AssetBatchSelectionProps) => {
  const {
    selectedAssetBatches,
    onSelectAssetBatches,
    assetBatchesForSelectedRows,
  } = useFeedContext();
  const { exportDestination } = useAdLibraryExportContext();

  const validAssetBatches = getValidAssetBatches(
    exportDestination,
    assetBatchesForSelectedRows,
    HTMLTemplatesMap,
  );

  const allAssetBatchesSelected =
    !!selectedAssetBatches.length &&
    selectedAssetBatches.length === validAssetBatches.length;
  const someAssetBatchesSelected =
    selectedAssetBatches.length > 0 && !allAssetBatchesSelected;

  const onAllCheckboxChange = () => {
    if (allAssetBatchesSelected) {
      onSelectAssetBatches([]);
    } else {
      let allAssetBatchIds = getAllAssetBatchIds(assetBatchesForSelectedRows);

      if (
        exportDestination === ExportDestination.AdLibrary ||
        exportDestination === ExportDestination.GoogleSpreadsheet
      ) {
        allAssetBatchIds = allAssetBatchIds.filter(
          assetBatchId => !HTMLTemplatesMap[assetBatchId],
        );
      }
      onSelectAssetBatches(allAssetBatchIds);
    }
  };

  const isAssetBatchDisabled = (assetBatchId: string) => {
    if (!exportDestination) return false;

    const isHTMLTemplate = HTMLTemplatesMap[assetBatchId];
    return (
      [
        ExportDestination.AdLibrary,
        ExportDestination.AdLibrary,
        ExportDestination.Portal,
      ].includes(exportDestination) && isHTMLTemplate
    );
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent, assetBatchId: string) =>
    e.target.checked
      ? onSelectAssetBatches([...selectedAssetBatches, assetBatchId])
      : onSelectAssetBatches(
          selectedAssetBatches.filter(id => id !== assetBatchId),
        );

  return (
    <Space direction="vertical" className={styles.checkboxContainer}>
      <Checkbox
        checked={allAssetBatchesSelected}
        indeterminate={someAssetBatchesSelected}
        onChange={onAllCheckboxChange}
      >
        All
      </Checkbox>
      {assetBatchesForSelectedRows.map(({ name, assetBatchId }) => {
        const val = assetBatchId || "";
        const isDisabled = isAssetBatchDisabled(val);

        return (
          <Tooltip
            key={val}
            placement="topLeft"
            trigger="hover"
            title={
              isDisabled
                ? "Asset batches containing HTML templates are not allowed for this export type"
                : ""
            }
          >
            <Checkbox
              checked={selectedAssetBatches.includes(val)}
              onChange={e => onCheckBoxChange(e, val)}
              data-cy={`assetBatchesToExport-${val}`}
              disabled={isDisabled}
            >
              <Tag key={name}>{name}</Tag>
            </Checkbox>
          </Tooltip>
        );
      })}
    </Space>
  );
};
